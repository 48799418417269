import type { GatsbyBrowser } from 'gatsby';

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: {
    location,
  },
}) => {
  if (location.search || (location.state != null && location.state.isNotDirect)) {
    return false;
  }

  return true;
};
